import { Badge } from "@/components/ui/badge";
import { X } from "lucide-react";

interface TagSelectorProps {
  availableTags: string[];
  selectedTags: string[];
  onTagToggle: (tag: string) => void;
}

export const TagSelector = ({
  availableTags,
  selectedTags,
  onTagToggle,
}: TagSelectorProps) => {
  return (
    <div className="flex flex-wrap gap-2">
      {availableTags.map((tag) => (
        <Badge
          key={tag}
          variant={selectedTags.includes(tag) ? "default" : "outline"}
          className="cursor-pointer"
          onClick={() => onTagToggle(tag)}
        >
          {tag}
          {selectedTags.includes(tag) && (
            <X
              className="ml-1 h-3 w-3"
              onClick={(e) => {
                e.stopPropagation();
                onTagToggle(tag);
              }}
            />
          )}
        </Badge>
      ))}
    </div>
  );
};
