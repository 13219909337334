import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Contact } from "@/types/contact";
import { TagSelector } from "../../ui/TagSelector";

const formSchema = z.object({
  firstName: z.string().min(2, "First name must be at least 2 characters"),
  lastName: z.string().min(2, "Last name must be at least 2 characters"),
  email: z.string().email("Invalid email address"),
  phoneNumber: z.string().min(10, "Phone number must be at least 10 digits"),
  phoneType: z.enum(["mobile", "work", "home", "other"]),
  group: z.string().optional(),
  tags: z.array(z.string()),
});

type FormValues = z.infer<typeof formSchema>;

interface EditContactDialogProps {
  contact: Partial<Contact>;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedContact: Partial<Contact>) => void;
}

export const EditContactDialog = ({
  contact,
  isOpen,
  onClose,
  onSave,
}: EditContactDialogProps) => {
  const [selectedTags, setSelectedTags] = useState<string[]>(
    contact.tags || [],
  );
  const { toast } = useToast();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: contact.firstName || "",
      lastName: contact.lastName || "",
      email: contact.emails?.[0]?.email || "",
      phoneNumber: contact.phones?.[0]?.number || "",
      phoneType:
        (contact.phones?.[0]?.type as "mobile" | "work" | "home" | "other") ||
        "mobile",
      group: contact.groups?.[0]?.name || "",
      tags: contact.tags || [],
    },
  });

  const handleTagToggle = (tag: string) => {
    setSelectedTags((prev) =>
      prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag],
    );
  };

  const onSubmit = async (values: FormValues) => {
    try {
      const updatedContact: Partial<Contact> = {
        ...contact,
        firstName: values.firstName,
        lastName: values.lastName,
        fullName: `${values.firstName} ${values.lastName}`,
        displayName: `${values.firstName} ${values.lastName}`,
        emails: [
          {
            email: values.email,
            type: "personal",
            isPrimary: true,
            isVerified: false,
            source: "manual",
            lastValidated: new Date(),
          },
        ],
        phones: [
          {
            number: values.phoneNumber,
            type: values.phoneType,
            isPrimary: true,
            countryCode: "+1",
            verified: false,
            source: "manual",
            lastValidated: new Date(),
          },
        ],
        tags: selectedTags,
        groups: values.group
          ? [
              {
                id: contact.groups?.[0]?.id || Date.now().toString(),
                name: values.group,
                joinDate: contact.groups?.[0]?.joinDate || new Date(),
                role: "member",
              },
            ]
          : [],
        updatedAt: new Date(),
      };

      onSave(updatedContact);
      onClose();
      toast({
        title: "Contact updated",
        description: "The contact has been successfully updated.",
      });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to update contact. Please try again.",
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Contact</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input placeholder="John" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Doe" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="john@example.com"
                      type="email"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneNumber"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <Input placeholder="1234567890" type="tel" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phoneType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Type</FormLabel>
                  <FormControl>
                    <select
                      {...field}
                      className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background"
                    >
                      <option value="mobile">Mobile</option>
                      <option value="work">Work</option>
                      <option value="home">Home</option>
                      <option value="other">Other</option>
                    </select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="group"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Group</FormLabel>
                  <FormControl>
                    <select
                      {...field}
                      className="w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background"
                    >
                      <option value="">Select a group...</option>
                      <option value="Donator">Donator</option>
                      <option value="Supporter">Supporter</option>
                      <option value="Volunteer">Volunteer</option>
                    </select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-2">
              <FormLabel>Tags</FormLabel>
              <TagSelector
                availableTags={[
                  "Family",
                  "Friend",
                  "Colleague",
                  "Partner",
                  "Client",
                  "VIP",
                ]}
                selectedTags={selectedTags}
                onTagToggle={handleTagToggle}
              />
            </div>
            <Button type="submit" className="w-full">
              Save Changes
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
