import { useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAuth } from "@/components/AuthProvider";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { useToast } from "@/components/ui/use-toast";
import { Helmet } from "react-helmet-async";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const settingsSchema = z.object({
  email: z.string().email(),
  notifications: z.object({
    email: z.boolean(),
    push: z.boolean(),
  }),
  theme: z.enum(["light", "dark", "system"]),
  language: z.string(),
});

type SettingsFormData = z.infer<typeof settingsSchema>;

const SettingsPage: React.FC = () => {
  const { t } = useTranslation("settings");
  const { user, updateUser } = useAuth();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<SettingsFormData>({
    resolver: zodResolver(settingsSchema),
    defaultValues: {
      email: user?.email || "",
      notifications: {
        email: true,
        push: true,
      },
      theme: "system",
      language: "en",
    },
  });

  const onSubmit = async (data: SettingsFormData) => {
    setIsLoading(true);

    try {
      const result = await updateUser({
        email: data.email,
        settings: {
          notifications: data.notifications,
          theme: data.theme,
          language: data.language,
        },
      });

      if (result?.error) {
        throw new Error(result.error.message);
      }

      toast({
        title: t("settings_updated"),
        description: t("settings_update_success"),
      });
    } catch (error) {
      console.error("Error updating settings:", error);
      toast({
        title: t("error"),
        description: error instanceof Error ? error.message : t("settings_update_error"),
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t("settings_title")} | Humanii Connect</title>
      </Helmet>
      <div className="container mx-auto py-8">
        <div className="mx-auto max-w-2xl">
          <h1 className="mb-6 text-3xl font-bold">{t("settings_title")}</h1>

          <Tabs defaultValue="general">
            <TabsList className="mb-4">
              <TabsTrigger value="general">{t("general")}</TabsTrigger>
              <TabsTrigger value="notifications">{t("notifications")}</TabsTrigger>
              <TabsTrigger value="appearance">{t("appearance")}</TabsTrigger>
            </TabsList>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <TabsContent value="general">
                  <Card>
                    <CardHeader>
                      <CardTitle>{t("general_settings")}</CardTitle>
                      <CardDescription>
                        {t("general_settings_description")}
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-4">
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t("email")}</FormLabel>
                            <FormControl>
                              <Input {...field} type="email" />
                            </FormControl>
                            <FormDescription>
                              {t("email_description")}
                            </FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="language"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t("language")}</FormLabel>
                            <FormControl>
                              <Input {...field} type="text" />
                            </FormControl>
                            <FormDescription>
                              {t("language_description")}
                            </FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CardContent>
                  </Card>
                </TabsContent>

                <TabsContent value="notifications">
                  <Card>
                    <CardHeader>
                      <CardTitle>{t("notification_settings")}</CardTitle>
                      <CardDescription>
                        {t("notification_settings_description")}
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-4">
                      <FormField
                        control={form.control}
                        name="notifications.email"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between rounded-lg border p-4">
                            <div className="space-y-0.5">
                              <FormLabel>{t("email_notifications")}</FormLabel>
                              <FormDescription>
                                {t("email_notifications_description")}
                              </FormDescription>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="notifications.push"
                        render={({ field }) => (
                          <FormItem className="flex items-center justify-between rounded-lg border p-4">
                            <div className="space-y-0.5">
                              <FormLabel>{t("push_notifications")}</FormLabel>
                              <FormDescription>
                                {t("push_notifications_description")}
                              </FormDescription>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </CardContent>
                  </Card>
                </TabsContent>

                <TabsContent value="appearance">
                  <Card>
                    <CardHeader>
                      <CardTitle>{t("appearance_settings")}</CardTitle>
                      <CardDescription>
                        {t("appearance_settings_description")}
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="space-y-4">
                      <FormField
                        control={form.control}
                        name="theme"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t("theme")}</FormLabel>
                            <FormControl>
                              <select {...field}>
                                <option value="light">{t("light")}</option>
                                <option value="dark">{t("dark")}</option>
                                <option value="system">{t("system")}</option>
                              </select>
                            </FormControl>
                            <FormDescription>
                              {t("theme_description")}
                            </FormDescription>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CardContent>
                  </Card>
                </TabsContent>
              </form>
            </Form>
          </Tabs>

          <div className="mt-6">
            <Button type="submit" disabled={isLoading}>
              {isLoading ? t("saving") : t("save_changes")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
