import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

interface OnboardingCompleteProps {
  onComplete: () => void;
}

export function OnboardingComplete({ onComplete }: OnboardingCompleteProps) {
  return (
    <Card className="w-full max-w-md mx-auto">
      <CardHeader>
        <CardTitle>Setup Complete!</CardTitle>
        <CardDescription>
          Your account has been successfully set up. You're ready to start using the platform.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Button onClick={onComplete} className="w-full">
          Get Started
        </Button>
      </CardContent>
    </Card>
  );
}
