import React, { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";

interface MainLayoutProps extends PropsWithChildren {
  showHeader?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  showHeader = true,
}) => {
  return (
    <div className="min-h-screen bg-white">
      {showHeader && <Header />}
      <main>{children || <Outlet />}</main>
    </div>
  );
};

export default MainLayout;
