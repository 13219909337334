import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useState } from "react";
import { Upload, Download } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import Papa from "papaparse";
import {
  Contact,
  ContactPhoneType,
  toSupabaseContact,
  toFrontendContact,
} from "@/types/contact";
import { supabase } from "@/integrations/supabase/client";

// Add an interface for CSV row data
interface CsvContactRow {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneType?: string;
  tags?: string;
  groups?: string;
}

interface ImportDialogProps {
  type: "csv" | "phone";
  trigger: React.ReactNode;
  onImportComplete?: (contacts: Partial<Contact>[]) => void;
}

const CSV_EXAMPLE = `firstName,lastName,email,phoneNumber,phoneType,tags,groups
John,Doe,john@example.com,1234567890,mobile,"family,friend","Group A,Group B"
Jane,Smith,jane@example.com,0987654321,work,colleague,"Group C"`;

export const ImportDialog = ({
  type,
  trigger,
  onImportComplete,
}: ImportDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [pastedContent, setPastedContent] = useState("");
  const { toast } = useToast();

  const handleDownloadExample = () => {
    const blob = new Blob([CSV_EXAMPLE], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "contacts_example.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  // Add return type to parseCsvContent
  const parseCsvContent = (content: string): Promise<Partial<Contact>[]> => {
    return new Promise((resolve, reject) => {
      Papa.parse<CsvContactRow>(content, {
        header: true,
        complete: (results) => {
          const contacts = results.data.map((row) => ({
            firstName: row.firstName,
            lastName: row.lastName,
            fullName: `${row.firstName} ${row.lastName}`,
            displayName: `${row.firstName} ${row.lastName}`,
            emails: [
              {
                email: row.email,
                type: "personal" as const,
                isPrimary: true,
                isVerified: false,
                source: "csv",
                lastValidated: new Date(),
              },
            ],
            phones: [
              {
                number: row.phoneNumber,
                type: (row.phoneType as ContactPhoneType) || "mobile",
                isPrimary: true,
                countryCode: "+1",
                verified: false,
                source: "csv",
                lastValidated: new Date(),
              },
            ],
            tags: row.tags
              ? row.tags.split(",").map((tag: string) => tag.trim())
              : [],
            groups: row.groups
              ? row.groups.split(",").map((group: string) => ({
                  id: group.trim(),
                  name: group.trim(),
                  joinDate: new Date(),
                  role: "member",
                }))
              : [],
          }));
          resolve(contacts);
        },
        error: (error: Error) => {
          console.error("Import error:", error);
          reject(error);
        },
      });
    });
  };

  const handleImport = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      if (type === "csv" && pastedContent) {
        const contacts = await parseCsvContent(pastedContent);

        // Convert contacts to Supabase format
        const supabaseContacts = contacts.map(toSupabaseContact);

        // Insert contacts into Supabase
        const { data, error } = await supabase
          .from("contacts")
          .insert(supabaseContacts)
          .select();

        if (error) throw error;

        // Convert the inserted contacts back to frontend format
        const frontendContacts = data.map((contact) => toFrontendContact(contact));

        // Update local state
        onImportComplete?.(frontendContacts);
        toast({
          title: "Import successful",
          description: `Successfully imported ${frontendContacts.length} contacts`,
        });
      }
    } catch (error) {
      console.error("Error importing contacts:", error);
      toast({
        variant: "destructive",
        title: "Import failed",
        description: error instanceof Error ? error.message : "Failed to import contacts",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>
            Import {type === "csv" ? "CSV File" : "Phone Contacts"}
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleImport} className="space-y-4">
          {type === "csv" ? (
            <>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <p className="text-sm text-muted-foreground">
                    Download our CSV template to see the required format
                  </p>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={handleDownloadExample}
                    className="h-8"
                  >
                    <Download className="h-4 w-4 mr-2" />
                    Example CSV
                  </Button>
                </div>
                <Input type="file" accept=".csv" className="cursor-pointer" />
                <div className="space-y-2">
                  <label className="text-sm font-medium">
                    Or paste your CSV content here:
                  </label>
                  <Textarea
                    placeholder="Paste your CSV content here..."
                    value={pastedContent}
                    onChange={(e) => setPastedContent(e.target.value)}
                    className="min-h-[200px] font-mono text-sm"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="text-center">
              <p className="text-sm text-muted-foreground mb-4">
                This will sync contacts from your device
              </p>
            </div>
          )}
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? (
              "Importing..."
            ) : (
              <>
                <Upload className="h-4 w-4 mr-2" />
                Import {type === "csv" ? "CSV" : "Phone Contacts"}
              </>
            )}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
