import { useEffect, useState } from "react";
import { useAuth } from "@/components/AuthProvider";
import { supabase } from "@/lib/supabase";
import { UserProfile, ProfileState, SocialMediaProfile, ProfileUpdateData } from "@/types/profile";
import { OnboardingFlow } from "@/components/OnboardingFlow";
import UserProfileView from "@/components/UserProfile";

export default function Profile() {
  const { user } = useAuth();
  const [profile, setProfile] = useState<ProfileState | null>(null);

  const parseSocialMediaHandles = (handles: any[]): SocialMediaProfile[] => {
    if (!Array.isArray(handles)) return [];
    
    return handles.map(handle => ({
      platform: handle.platform,
      handle: handle.handle || "",
      url: `https://${handle.platform}.com/${handle.handle || ""}`
    }));
  };

  useEffect(() => {
    if (!user?.id) return;

    const fetchProfile = async () => {
      setProfile(prev => ({ ...prev, loading: true, error: null } as ProfileState));
      
      try {
        const { data, error } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", user.id)
          .single();

        if (error) throw error;

        const userProfile: UserProfile = {
          id: data.id,
          email: user.email || null,
          fullName: data.full_name || "",
          firstName: data.first_name,
          lastName: data.last_name,
          organizationName: data.organization_name,
          role: data.role,
          socialMediaProfiles: parseSocialMediaHandles(data.social_media_profiles),
          avatarUrl: data.avatar_url,
          isOnboarded: data.is_onboarded,
          createdAt: data.created_at,
          updatedAt: data.updated_at
        };

        setProfile({
          ...userProfile,
          loading: false,
          error: null
        });
      } catch (error) {
        setProfile(prev => ({
          ...(prev || {
            id: user.id,
            email: user.email || null,
            fullName: "",
            socialMediaProfiles: [],
            avatarUrl: null,
            isOnboarded: false
          }),
          loading: false,
          error: error instanceof Error ? error.message : "Failed to load profile"
        }) as ProfileState);
      }
    };

    fetchProfile();
  }, [user?.id]);

  const handleSave = async (updates: ProfileUpdateData): Promise<void> => {
    if (!user?.id || !profile) return;

    setProfile(prev => ({ ...prev, loading: true, error: null } as ProfileState));

    try {
      const { error } = await supabase
        .from("profiles")
        .update({
          full_name: updates.fullName,
          first_name: updates.firstName,
          last_name: updates.lastName,
          organization_name: updates.organizationName,
          role: updates.role,
          social_media_profiles: updates.socialMediaProfiles?.map(profile => ({
            platform: profile.platform,
            handle: profile.handle,
            url: profile.url
          })),
          is_onboarded: updates.isOnboarded
        })
        .eq("id", user.id);

      if (error) throw error;

      setProfile(prev => ({
        ...prev,
        ...updates,
        loading: false,
        error: null
      }) as ProfileState);
    } catch (error) {
      setProfile(prev => ({
        ...prev,
        loading: false,
        error: error instanceof Error ? error.message : "Failed to update profile"
      }) as ProfileState);
    }
  };

  if (!user || !profile) return null;

  if (!profile.isOnboarded) {
    return (
      <OnboardingFlow
        user={user}
        onComplete={() => handleSave({ 
          fullName: profile.fullName,
          isOnboarded: true 
        })}
      />
    );
  }

  return (
    <UserProfileView
      user={profile}
      onSave={handleSave}
      loading={profile.loading}
      error={profile.error}
    />
  );
}
