import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: {
      'en-US': ['en'],
      'default': ['en']
    },
    debug: process.env.NODE_ENV === "development",

    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
      addPath: "/locales/add/{{lng}}/{{ns}}",
      allowMultiLoading: false,
      crossDomain: true,
      withCredentials: false,
      requestOptions: {
        mode: "cors",
        credentials: "same-origin",
        cache: "default",
      },
    },

    interpolation: {
      escapeValue: false,
    },

    defaultNS: "common",
    ns: [
      "common",
      "home",
      "about",
      "howItWorks",
      "pricing",
      "contact",
      "raisingMoney",
      "auth"
    ],

    // Simplified language detection
    load: "languageOnly", // This will strip the region (e.g., 'en-US' -> 'en')
    preload: ["en"],

    // Development helpers
    saveMissing: process.env.NODE_ENV === "development",
    missingKeyHandler: (lng, ns, key) => {
      if (process.env.NODE_ENV === "development") {
        console.warn(
          `Missing translation key: ${key} in namespace: ${ns} for language: ${lng}`,
        );
      }
    },
  });

export default i18n;
