import type { Database } from "@/integrations/supabase/types";

export type ContactEmailType = "personal" | "work" | "other";
export type ContactPhoneType = "mobile" | "work" | "home" | "other";
export type ContactAddressType = "home" | "work" | "other";
export type SocialPlatform =
  | "facebook"
  | "linkedin"
  | "twitter"
  | "instagram"
  | "other";
export type MessagingPlatform =
  | "whatsapp"
  | "messenger"
  | "telegram"
  | "slack"
  | "teams"
  | "other";
export type ImportSourceType =
  | "csv"
  | "phone"
  | "email"
  | "social"
  | "crm"
  | "manual";

export interface ContactEmail {
  email: string;
  type: ContactEmailType;
  isPrimary: boolean;
  isVerified: boolean;
  source: string;
  lastValidated: Date;
}

export interface ContactPhone {
  number: string;
  type: ContactPhoneType;
  isPrimary: boolean;
  countryCode: string;
  verified: boolean;
  source: string;
  lastValidated: Date;
}

export interface ContactAddress {
  type: ContactAddressType;
  street1: string;
  street2?: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  isPrimary: boolean;
  source: string;
  lastValidated: Date;
}

export interface SocialProfile {
  platform: SocialPlatform;
  username: string;
  profileUrl: string;
  followersCount: number;
  connectionDegree: number;
  lastSynced: Date;
  isConnected: boolean;
  accessLevel: "public" | "connected" | "private";
}

export interface MessagingAccount {
  platform: MessagingPlatform;
  identifier: string;
  isActive: boolean;
  lastActivity: Date;
  preferredPlatform: boolean;
}

export interface ContactRelationship {
  contactId: string;
  relationship: string;
  strength: number;
}

export interface Organization {
  name: string;
  role: string;
  primary: boolean;
  joinDate: Date;
  source: string;
}

export interface ContactGroup {
  id: string;
  name: string;
  joinDate: Date;
  role: string;
}

export interface CampaignHistory {
  campaignId: string;
  status: "contacted" | "responded" | "donated" | "declined";
  lastInteraction: Date;
  totalDonations: number;
  responses: number;
}

export interface CommunicationPreferences {
  preferredChannel: string;
  preferredLanguage: string;
  preferredTime: string;
  frequency: "daily" | "weekly" | "monthly" | "quarterly";
  optOuts: string[];
  unsubscribed: boolean;
  lastUpdated: Date;
}

export interface ContactQuality {
  score: number;
  completeness: number;
  lastVerified: Date;
  issues: string[];
  duplicateProbability: number;
}

export interface ContactEngagement {
  score: number;
  lastContact: Date;
  responseRate: number;
  preferredTimes: string[];
  interests: string[];
  lastEngaged: Date;
}

export interface ContactNote {
  id: string;
  content: string;
  category: string;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
}

export interface ContactPrivacy {
  consented: boolean;
  consentDate: Date;
  dataRetention: Date;
  dataUsagePermissions: string[];
  marketingPermissions: string[];
  source: string;
  proof: string;
}

export interface ImportSource {
  type: ImportSourceType;
  platform?: string;
  importDate: Date;
  filename?: string;
  batchId: string;
  mappings?: Record<string, string>;
  validationResults?: {
    valid: boolean;
    errors: string[];
    warnings: string[];
  };
}

export interface Contact {
  // System Fields
  id: string;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
  importSource: ImportSource;
  importId: string;
  mergedIds: string[];

  // Basic Information
  firstName: string;
  lastName: string;
  fullName: string;
  displayName: string;
  prefix?: string;
  suffix?: string;
  nickname?: string;
  gender?: string;
  birthDate?: Date;
  company?: string;
  jobTitle?: string;
  department?: string;

  // Contact Information
  emails: ContactEmail[];
  phones: ContactPhone[];
  addresses: ContactAddress[];
  socialProfiles: SocialProfile[];
  messagingAccounts: MessagingAccount[];

  // Relationships & Organizations
  relationships: ContactRelationship[];
  organizations: Organization[];
  groups: ContactGroup[];

  // Campaign & Communication
  campaignHistory: CampaignHistory[];
  communicationPreferences: CommunicationPreferences;

  // Classification
  tags: string[];
  categories: string[];
  segments: string[];
  lists: string[];

  // Custom Fields
  customFields: {
    [key: string]: {
      value: any;
      type: string;
      source: string;
      lastUpdated: Date;
    };
  };

  // Quality & Engagement
  quality: ContactQuality;
  engagement: ContactEngagement;

  // Notes & Privacy
  notes: ContactNote[];
  privacy: ContactPrivacy;
}

export type SupabaseContact = Database["public"]["Tables"]["contacts"]["Row"];
export type SupabaseContactInsert = Database["public"]["Tables"]["contacts"]["Insert"];
export type SupabaseContactUpdate = Database["public"]["Tables"]["contacts"]["Update"];

export const toSupabaseContact = (contact: Partial<Contact>): SupabaseContactInsert => ({
  id: contact.id,
  user_id: contact.userId,
  first_name: contact.firstName || null,
  last_name: contact.lastName || null,
  email: contact.emails?.[0]?.email || null,
  phone: contact.phones?.[0]?.number || null,
  tags: contact.tags || [],
  custom_fields: JSON.stringify({
    groups: contact.groups?.map(g => g.name) || [],
    social_profiles: contact.socialProfiles || [],
    messaging_accounts: contact.messagingAccounts || [],
    communication_preferences: contact.communicationPreferences,
    quality: contact.quality,
    engagement: contact.engagement,
  }),
  created_at: contact.createdAt?.toISOString(),
  updated_at: new Date().toISOString()
});

export const toFrontendContact = (dbContact: SupabaseContact): Partial<Contact> => ({
  id: dbContact.id,
  userId: dbContact.user_id || undefined,
  firstName: dbContact.first_name || undefined,
  lastName: dbContact.last_name || undefined,
  fullName: `${dbContact.first_name || ''} ${dbContact.last_name || ''}`.trim(),
  displayName: `${dbContact.first_name || ''} ${dbContact.last_name || ''}`.trim(),
  emails: dbContact.email ? [{
    email: dbContact.email,
    type: 'personal',
    isPrimary: true,
    isVerified: false,
    source: 'supabase',
    lastValidated: new Date(),
  }] : [],
  phones: dbContact.phone ? [{
    number: dbContact.phone,
    type: 'mobile',
    isPrimary: true,
    countryCode: '+1',
    verified: false,
    source: 'supabase',
    lastValidated: new Date(),
  }] : [],
  tags: dbContact.tags || [],
  ...tryParseCustomFields(dbContact.custom_fields),
  createdAt: dbContact.created_at ? new Date(dbContact.created_at) : undefined,
  updatedAt: dbContact.updated_at ? new Date(dbContact.updated_at) : undefined,
});

const tryParseCustomFields = (customFields: any) => {
  try {
    const parsed = typeof customFields === 'string' ? JSON.parse(customFields) : customFields;
    return {
      groups: (parsed?.groups || []).map((name: string) => ({
        id: name,
        name,
        joinDate: new Date(),
        role: 'member',
      })),
      socialProfiles: parsed?.social_profiles || [],
      messagingAccounts: parsed?.messaging_accounts || [],
      communicationPreferences: parsed?.communication_preferences,
      quality: parsed?.quality,
      engagement: parsed?.engagement,
    };
  } catch (e) {
    console.error('Error parsing custom fields:', e);
    return {};
  }
};
