import { Button } from "@/components/ui/button";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import { Globe, User, LogIn, UserPlus, Home, CreditCard } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useState, useEffect } from "react";
import { toast } from "sonner";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session, signOut } = useAuth();
  const [currentLanguage, setCurrentLanguage] = useState("English");

  const handleLanguageChange = (newLanguage: string) => {
    setCurrentLanguage(newLanguage);
    toast.success(`Language changed to ${newLanguage}`);
  };

  const handleSignOut = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      console.log("Initiating sign out...");
      await signOut();
    } catch (error) {
      console.error("Error in handleSignOut:", error);
      toast.error("Failed to sign out");
    }
  };

  const handleNavigation = (path: string, e?: React.MouseEvent) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    // Handle pricing link specially
    if (path === "/#pricing") {
      if (location.pathname === "/") {
        // If we're already on the homepage, just scroll to pricing
        document
          .getElementById("pricing")
          ?.scrollIntoView({ behavior: "smooth" });
      } else {
        // If we're on another page, navigate to homepage then scroll to pricing
        navigate("/", { state: { scrollTo: "pricing" } });
      }
      return;
    }

    navigate(path);
  };

  // Add effect to handle scroll after navigation
  useEffect(() => {
    if (location.state?.scrollTo === "pricing") {
      document
        .getElementById("pricing")
        ?.scrollIntoView({ behavior: "smooth" });
      // Clean up the state
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  return (
    <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="container flex h-14 items-center justify-between">
        <div className="flex items-center gap-4">
          <a className="flex items-center space-x-2" href="/">
            <img
              src="/icons/icon-192x192.png"
              alt="Humanii Connect"
              className="h-6 w-6"
            />
            <span className="hidden font-bold sm:inline-block">
              Humanii Connect
            </span>
          </a>
          <nav className="hidden md:flex items-center space-x-4">
            <Button
              variant="ghost"
              onClick={() => handleNavigation("/")}
              className={`hover:bg-[#5B2E91]/10 ${location.pathname === "/" ? "text-[#5B2E91] bg-[#5B2E91]/10" : ""}`}
            >
              <Home className="h-4 w-4 mr-2" />
              Home
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleNavigation("/how-it-works")}
              className={`hover:bg-[#5B2E91]/10 ${location.pathname === "/how-it-works" ? "text-[#5B2E91] bg-[#5B2E91]/10" : ""}`}
            >
              How It Works
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleNavigation("/about")}
              className={`hover:bg-[#5B2E91]/10 ${location.pathname === "/about" ? "text-[#5B2E91] bg-[#5B2E91]/10" : ""}`}
            >
              About
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleNavigation("/#pricing")}
              className={`hover:bg-[#5B2E91]/10 ${location.hash === "#pricing" ? "text-[#5B2E91] bg-[#5B2E91]/10" : ""}`}
            >
              Pricing
            </Button>
            <Button
              variant="ghost"
              onClick={() => handleNavigation("/contact")}
              className={`hover:bg-[#5B2E91]/10 ${location.pathname === "/contact" ? "text-[#5B2E91] bg-[#5B2E91]/10" : ""}`}
            >
              Contact
            </Button>
          </nav>
        </div>
        <div className="flex items-center space-x-2">
          {/* Language Switcher */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className="hover:bg-[#5B2E91]/10"
              >
                <Globe className="h-5 w-5" />
                <span className="hidden md:inline text-sm ml-2">
                  {currentLanguage}
                </span>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-40">
              <DropdownMenuItem
                onClick={() => handleLanguageChange("English")}
                className="hover:bg-[#5B2E91]/10 hover:text-[#5B2E91]"
              >
                English
              </DropdownMenuItem>
              <DropdownMenuItem
                onClick={() => handleLanguageChange("Français")}
                className="hover:bg-[#5B2E91]/10 hover:text-[#5B2E91]"
              >
                Français
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>

          {/* Auth Section */}
          {session ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="hover:bg-[#5B2E91]/10">
                  <User className="h-4 w-4 mr-2" />
                  Account
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-40">
                <DropdownMenuItem
                  onClick={(e) => handleNavigation("/", e)}
                  className="hover:bg-[#5B2E91]/10 hover:text-[#5B2E91]"
                >
                  <Home className="h-4 w-4 mr-2" />
                  Home
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={(e) => handleNavigation("/profile", e)}
                  className="hover:bg-[#5B2E91]/10 hover:text-[#5B2E91]"
                >
                  <User className="h-4 w-4 mr-2" />
                  Profile
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={(e) => handleNavigation("/billing", e)}
                  className="hover:bg-[#5B2E91]/10 hover:text-[#5B2E91]"
                >
                  <CreditCard className="h-4 w-4 mr-2" />
                  Billing
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={handleSignOut}
                  className="hover:bg-[#5B2E91]/10 hover:text-[#5B2E91]"
                >
                  <LogIn className="h-4 w-4 mr-2" />
                  Sign Out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <div className="flex items-center space-x-2">
              <Button
                variant="ghost"
                onClick={(e) => handleNavigation("/login", e)}
                className="flex items-center gap-2"
              >
                <LogIn className="h-4 w-4" />
                Sign In
              </Button>
              <Button
                variant="default"
                onClick={(e) => handleNavigation("/register", e)}
                className="flex items-center gap-2 bg-[#5B2E91] hover:bg-[#5B2E91]/90"
              >
                <UserPlus className="h-4 w-4" />
                Sign Up
              </Button>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
