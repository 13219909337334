import { useAuth } from "@/components/AuthProvider";
import { DashboardStats } from "../components/dashboard/DashboardStats";
import { CampaignsTab } from "../components/dashboard/tabs/CampaignsTab";
import { ContactsTab } from "../components/dashboard/tabs/ContactsTab";
import { AnalyticsTab } from "@/components/dashboard/tabs/AnalyticsTab";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Plus, Settings } from "lucide-react";

const Dashboard = () => {
  const { session } = useAuth();

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Header Section */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
          <p className="text-gray-600">Welcome back, {session?.user?.email}</p>
        </div>
        <div className="flex gap-4">
          <Button variant="outline">
            <Settings className="h-4 w-4 mr-2" />
            Settings
          </Button>
          <Button className="bg-[#4A1B8F] hover:bg-[#4A1B8F]/90">
            <Plus className="h-4 w-4 mr-2" />
            New Campaign
          </Button>
        </div>
      </div>

      {/* Stats Section */}
      <DashboardStats />

      {/* Tabs Section */}
      <Tabs defaultValue="contacts" className="mt-8">
        <TabsList>
          <TabsTrigger value="contacts">Contacts</TabsTrigger>
          <TabsTrigger value="campaigns">Campaigns</TabsTrigger>
          <TabsTrigger value="analytics">Analytics</TabsTrigger>
        </TabsList>
        <TabsContent value="contacts">
          <ContactsTab />
        </TabsContent>
        <TabsContent value="campaigns">
          <CampaignsTab />
        </TabsContent>
        <TabsContent value="analytics">
          <AnalyticsTab />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Dashboard;
