import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Button } from '@/components/ui/button';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/components/AuthProvider';

const VerifyEmail = () => {
  const { t } = useTranslation('auth');
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user } = useAuth();
  
  const email = location.state?.email || user?.email;

  useEffect(() => {
    if (!email) {
      navigate('/auth/login');
    }
  }, [email, navigate]);

  const handleResendEmail = async () => {
    try {
      // TODO: Implement resend verification email
      toast({
        title: t('verification.success'),
        description: t('verification.message', { email }),
      });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: t('verification.errors.tooManyRequests'),
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('verification.title')} | Humanii Connect</title>
      </Helmet>
      <div className="container mx-auto flex h-screen items-center justify-center">
        <div className="w-full max-w-md space-y-6 rounded-lg border p-6 shadow-lg">
          <div className="space-y-2 text-center">
            <h1 className="text-2xl font-bold">{t('verification.title')}</h1>
            <p className="text-muted-foreground">
              {t('verification.message', { email })}
            </p>
            <p className="text-sm text-muted-foreground">
              {t('verification.instructions')}
            </p>
          </div>

          <Button
            variant="outline"
            className="w-full"
            onClick={handleResendEmail}
          >
            {t('verification.resend')}
          </Button>

          <div className="text-center text-sm">
            <Button
              variant="link"
              className="text-muted-foreground hover:text-primary"
              onClick={() => navigate('/auth/login')}
            >
              {t('signIn.title')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
