import React from "react";
import { createRoot } from "react-dom/client";
import { Toaster } from "sonner";
import { AuthProvider } from "@/components/AuthProvider";
import RouteProvider from "@/components/providers/RouteProvider";
import "./index.css";
import "./i18n";

function App() {
  return (
    <React.StrictMode>
      <AuthProvider>
        <RouteProvider />
        <Toaster />
      </AuthProvider>
    </React.StrictMode>
  );
}

const container = document.getElementById("root");
if (!container) {
  throw new Error("Failed to find the root element");
}

const root = createRoot(container);
root.render(<App />);
