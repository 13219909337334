import { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { supabase } from "@/lib/supabase";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Loader2, User } from "lucide-react";
import { toast } from "sonner";

interface ProfileData {
  firstName: string;
  lastName: string;
  termsAccepted: boolean;
  privacyAccepted: boolean;
}

interface ProfileError {
  field: keyof ProfileData;
  message: string;
}

export const CompleteProfile = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<ProfileData>({
    firstName: "",
    lastName: "",
    termsAccepted: false,
    privacyAccepted: false,
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ProfileError | null>(null);

  const handleInputChange = (field: keyof ProfileData) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setData((prev) => ({ ...prev, [field]: value }));
    if (error?.field === field) {
      setError(null);
    }
  };

  const validateForm = (): boolean => {
    if (!data.firstName.trim()) {
      setError({ field: "firstName", message: "First name is required" });
      return false;
    }
    if (!data.lastName.trim()) {
      setError({ field: "lastName", message: "Last name is required" });
      return false;
    }
    if (!data.termsAccepted) {
      setError({ field: "termsAccepted", message: "You must accept the terms of service" });
      return false;
    }
    if (!data.privacyAccepted) {
      setError({ field: "privacyAccepted", message: "You must accept the privacy policy" });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;
    
    setLoading(true);
    setError(null);

    try {
      const { data: { user: supabaseUser } } = await supabase.auth.getUser();
      
      if (!supabaseUser?.id) {
        throw new Error('User not authenticated');
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({
          first_name: data.firstName.trim(),
          last_name: data.lastName.trim(),
          terms_accepted: data.termsAccepted,
          privacy_accepted: data.privacyAccepted,
          is_onboarded: true,
          updated_at: new Date().toISOString(),
        })
        .eq("id", supabaseUser.id);

      if (updateError) throw updateError;

      toast.success("Profile completed successfully!");
      navigate("/dashboard");
    } catch (err) {
      const message = err instanceof Error ? err.message : "Failed to update profile";
      toast.error(message);
      setLoading(false);
    }
  };

  return (
    <div className="space-y-6">
      {/* Sign In Header */}
      <div className="text-center">
        <h2 className="text-3xl font-bold text-white">Complete Your Profile</h2>
        <p className="mt-2 text-gray-400">
          Please provide your information to continue
        </p>
      </div>

      {/* Error Message */}
      {error && (
        <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded-md text-sm">
          {error.message}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <div>
            <Label htmlFor="firstName" className="text-white">First Name</Label>
            <div className="relative">
              <User className="absolute left-3 top-3 h-4 w-4 text-gray-300" aria-hidden="true" />
              <Input
                id="firstName"
                value={data.firstName}
                onChange={handleInputChange("firstName")}
                className="pl-10 bg-white/10 border-white/20 text-white placeholder:text-gray-300 focus:border-[#7B4EC3]"
                placeholder="Enter your first name"
                disabled={loading}
              />
            </div>
          </div>

          <div>
            <Label htmlFor="lastName" className="text-white">Last Name</Label>
            <div className="relative">
              <User className="absolute left-3 top-3 h-4 w-4 text-gray-300" aria-hidden="true" />
              <Input
                id="lastName"
                value={data.lastName}
                onChange={handleInputChange("lastName")}
                className="pl-10 bg-white/10 border-white/20 text-white placeholder:text-gray-300 focus:border-[#7B4EC3]"
                placeholder="Enter your last name"
                disabled={loading}
              />
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={data.termsAccepted}
              onCheckedChange={(checked) =>
                setData((prev) => ({ ...prev, termsAccepted: checked as boolean }))
              }
              disabled={loading}
            />
            <Label htmlFor="terms" className="text-sm text-gray-300">
              I accept the{" "}
              <Link
                to="/terms"
                target="_blank"
                className="text-[#7B4EC3] hover:text-[#6B3EB3] font-semibold"
              >
                terms of service
              </Link>
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="privacy"
              checked={data.privacyAccepted}
              onCheckedChange={(checked) =>
                setData((prev) => ({ ...prev, privacyAccepted: checked as boolean }))
              }
              disabled={loading}
            />
            <Label htmlFor="privacy" className="text-sm text-gray-300">
              I accept the{" "}
              <Link
                to="/privacy"
                target="_blank"
                className="text-[#7B4EC3] hover:text-[#6B3EB3] font-semibold"
              >
                privacy policy
              </Link>
            </Label>
          </div>
        </div>

        <Button
          type="submit"
          className="w-full bg-[#7B4EC3] hover:bg-[#6B3EB3] text-white focus:ring-2 focus:ring-offset-2 focus:ring-[#7B4EC3]"
          disabled={loading}
        >
          {loading ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Completing Profile...
            </>
          ) : (
            "Continue"
          )}
        </Button>
      </form>
    </div>
  );
};
