import { useState } from "react";
import { User } from "@supabase/supabase-js";
import { AccountSetup } from "./AccountSetup";
import { ProfileSetup } from "./ProfileSetup";
import { OnboardingComplete } from "./OnboardingComplete";

type OnboardingStep = "account" | "profile" | "complete";

interface OnboardingFlowProps {
  user: User;
  onComplete: () => void;
}

export function OnboardingFlow({ user, onComplete }: OnboardingFlowProps) {
  const [currentStep, setCurrentStep] = useState<OnboardingStep>("account");

  const handleStepComplete = (step: OnboardingStep) => {
    switch (step) {
      case "account":
        setCurrentStep("profile");
        break;
      case "profile":
        setCurrentStep("complete");
        break;
      case "complete":
        onComplete();
        break;
    }
  };

  if (!user?.email) {
    return <div>Error: User email is required for onboarding</div>;
  }

  switch (currentStep) {
    case "account":
      return (
        <AccountSetup
          email={user.email}
          onComplete={() => handleStepComplete("account")}
        />
      );
    case "profile":
      return (
        <ProfileSetup
          userId={user.id}
          onComplete={() => handleStepComplete("profile")}
        />
      );
    case "complete":
      return <OnboardingComplete onComplete={() => handleStepComplete("complete")} />;
    default:
      return null;
  }
}
