import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";
import { CompleteProfile } from "@/components/auth/CompleteProfile";
import Footer from "@/components/Footer";

const CompleteProfilePage = () => {
  const { user, profile, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading) {
      if (!user) {
        navigate("/auth/login");
      } else if (profile?.isOnboarded) {
        navigate("/dashboard");
      }
    }
  }, [user, profile, isLoading, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#1A1A1A]">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#5B2E91]"></div>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-1">
        {/* Left Panel */}
        <div className="hidden lg:flex w-1/2 bg-gradient-to-b from-[#5B2E91] to-[#2E7BB7] p-12 relative">
          <div className="absolute top-8 left-8">
            <img
              src="/images/logo.png"
              alt="Humanii Connect"
              className="h-8 w-auto"
            />
          </div>
          <div className="w-full flex flex-col justify-center">
            <h1 className="text-4xl font-bold text-white mb-4">
              Complete Your Profile
            </h1>
            <p className="text-lg text-white/80">
              Help us personalize your experience by providing some additional information.
            </p>
          </div>
        </div>

        {/* Right Panel */}
        <div className="w-full lg:w-1/2 bg-[#1A1A1A] flex flex-col">
          <div className="flex-grow flex items-center justify-center px-8 py-12">
            <div className="w-full max-w-md space-y-8">
              {/* Mobile Logo */}
              <div className="lg:hidden">
                <img
                  src="/images/logo.png"
                  alt="Humanii Connect"
                  className="h-8 w-auto mx-auto"
                />
              </div>

              {/* Profile Form */}
              <CompleteProfile />
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default CompleteProfilePage;
