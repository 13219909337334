import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";
import Footer from "@/components/Footer";
import { Loader2 } from "lucide-react";

export default function LoginPage() {
  const { user, isLoading, signInWithGoogle, signInWithEmail } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!isLoading && user) {
      navigate("/dashboard");
    }
  }, [user, isLoading, navigate]);

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);
    
    try {
      await signInWithEmail(email, password);
    } catch (err) {
      setError("Invalid email or password. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setError("");
    setIsSubmitting(true);
    
    try {
      await signInWithGoogle();
    } catch (err) {
      setError("Failed to sign in with Google. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-[#1A1A1A]">
        <Loader2 className="h-8 w-8 animate-spin text-[#5B2E91]" />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex flex-1">
        {/* Left Panel */}
        <div className="hidden lg:flex w-1/2 bg-gradient-to-b from-[#5B2E91] to-[#2E7BB7] p-12 relative">
          <div className="w-full flex flex-col items-center justify-center">
            <img
              src="/logo.svg"
              alt="Humanii Connect"
              className="w-[350px] h-[350px] object-contain mb-8"
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = '/logo.png';
              }}
            />
            <h1 className="text-4xl font-bold text-white mb-4 text-center">
              Welcome to Humanii Connect
            </h1>
            <p className="text-lg text-white/80 text-center">
              Connect, collaborate, and make a difference in your community.
            </p>
          </div>
        </div>

        {/* Right Panel */}
        <div className="w-full lg:w-1/2 bg-[#1A1A1A] flex flex-col">
          <div className="flex-grow flex items-center justify-center px-8 py-12">
            <div className="w-full max-w-md space-y-8">
              {/* Mobile Logo */}
              <div className="lg:hidden flex flex-col items-center">
                <img
                  src="/logo.svg"
                  alt="Humanii Connect"
                  className="w-[200px] h-[200px] object-contain mb-8"
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = '/logo.png';
                  }}
                />
              </div>

              {/* Sign In Header */}
              <div className="text-center">
                <h2 className="text-3xl font-bold text-white">Welcome back</h2>
                <p className="mt-2 text-gray-400">
                  Sign in to continue to Humanii Connect
                </p>
              </div>

              {/* Error Message */}
              {error && (
                <div className="bg-red-500/10 border border-red-500 text-red-500 px-4 py-2 rounded-md text-sm">
                  {error}
                </div>
              )}

              {/* Sign In Form */}
              <form onSubmit={handleEmailSignIn} className="mt-8 space-y-6">
                <div className="space-y-4">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-white">
                      Email
                    </label>
                    <input
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      className="mt-1 block w-full rounded-md bg-[#2A2A2A] border border-gray-600 text-white px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#5B2E91]"
                      placeholder="Enter your email"
                      disabled={isSubmitting}
                    />
                  </div>

                  <div>
                    <label htmlFor="password" className="block text-sm font-medium text-white">
                      Password
                    </label>
                    <input
                      id="password"
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      className="mt-1 block w-full rounded-md bg-[#2A2A2A] border border-gray-600 text-white px-4 py-2 focus:outline-none focus:ring-2 focus:ring-[#5B2E91]"
                      placeholder="Enter your password"
                      disabled={isSubmitting}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-white bg-[#5B2E91] hover:bg-[#4A2575] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#5B2E91] disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? (
                    <Loader2 className="h-5 w-5 animate-spin" />
                  ) : (
                    "Sign in"
                  )}
                </button>
              </form>

              {/* Divider */}
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-600"></div>
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-[#1A1A1A] text-gray-400">or continue with</span>
                </div>
              </div>

              {/* Google Sign In */}
              <button
                type="button"
                onClick={handleGoogleSignIn}
                disabled={isSubmitting}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 border border-gray-600 rounded-md bg-[#2A2A2A] text-white hover:bg-[#353535] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <>
                    <img
                      src="/google-icon.svg"
                      alt="Google"
                      className="w-5 h-5"
                    />
                    <span>Sign in with Google</span>
                  </>
                )}
              </button>

              {/* Sign Up Link */}
              <p className="text-center text-sm text-gray-400">
                Don't have an account?{" "}
                <a href="/auth/register" className="text-[#7B4EC3] hover:text-[#6B3EB3] font-semibold">
                  Sign up
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}
