import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import { useAuth } from '@/components/AuthProvider';
import routes, { ExtendedRoute } from '@/routes';
import { LoadingSpinner } from '@/components/ui/loading-spinner';

interface RouteProviderProps {
  fallback?: React.ReactNode;
}

function RouteProvider({ fallback = <LoadingSpinner /> }: RouteProviderProps) {
  const { user, isLoading } = useAuth();

  const processRoutes = (routes: ExtendedRoute[]): ExtendedRoute[] => {
    return routes.map((route) => {
      const processedRoute = { ...route };

      if (route.children) {
        processedRoute.children = processRoutes(route.children);
      }

      if (route.auth && !user) {
        processedRoute.element = <Navigate to="/login" replace />;
      }

      return processedRoute;
    });
  };

  const processedRoutes = processRoutes(routes);
  const router = createBrowserRouter(processedRoutes);

  if (isLoading) {
    return fallback;
  }

  return <RouterProvider router={router} />;
}

export default RouteProvider;
