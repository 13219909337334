import { useState, useCallback, useMemo, useTransition } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/components/AuthProvider";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { Loader2, Mail, Lock, User } from "lucide-react";
import { supabase } from "@/lib/supabase";
import type { Provider } from "@supabase/supabase-js";
import Footer from "@/components/Footer";

export default function RegisterPage() {
  const [, startTransition] = useTransition();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    startTransition(() => {
      setFormData(prev => ({ ...prev, [name]: value }));
    });
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { error } = await signUp({ 
        email: formData.email, 
        password: formData.password,
        fullName: formData.name
      });

      if (error) {
        toast({
          variant: "destructive",
          title: "Registration failed",
          description: error.message
        });
        return;
      }

      toast({
        title: "Registration successful!",
        description: "Please check your email to verify your account."
      });
      
      navigate("/auth/verify-email", { state: { email: formData.email } });
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Registration failed",
        description: error instanceof Error ? error.message : "An unexpected error occurred"
      });
    } finally {
      setIsLoading(false);
    }
  }, [formData, navigate, toast]);

  const handleGoogleSignUp = useCallback(async () => {
    setIsGoogleLoading(true);
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google' as Provider,
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });

      if (error) throw error;
      // Redirect is handled automatically by Supabase
    } catch (error) {
      toast({
        variant: "destructive",
        title: "Google sign up failed",
        description: error instanceof Error ? error.message : "An unexpected error occurred"
      });
      setIsGoogleLoading(false);
    }
  }, [toast]);

  const FormFields = useMemo(() => (
    <>
      <div className="space-y-4">
        <h1 className="text-3xl font-bold tracking-tight text-white">
          Create an account
        </h1>
        <p className="text-base text-gray-300">
          Join Humanii Connect and start making a difference
        </p>
      </div>
      <div className="space-y-2">
        <Label htmlFor="name">Full Name</Label>
        <div className="relative">
          <User className="absolute left-3 top-3 h-4 w-4 text-gray-300" aria-hidden="true" />
          <Input
            id="name"
            name="name"
            type="text"
            placeholder="Enter your full name"
            value={formData.name}
            onChange={handleInputChange}
            className="pl-10 bg-white/10 border-white/20 text-white placeholder:text-gray-300 focus:border-[#7B4EC3]"
            autoComplete="name"
            aria-label="Full name"
            required
          />
        </div>
      </div>
      <div className="space-y-2">
        <Label htmlFor="email">Email</Label>
        <div className="relative">
          <Mail className="absolute left-3 top-3 h-4 w-4 text-gray-300" aria-hidden="true" />
          <Input
            id="email"
            name="email"
            type="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleInputChange}
            className="pl-10 bg-white/10 border-white/20 text-white placeholder:text-gray-300 focus:border-[#7B4EC3]"
            autoComplete="email"
            aria-label="Email address"
            required
          />
        </div>
      </div>
      <div className="space-y-2">
        <Label htmlFor="password">Password</Label>
        <div className="relative">
          <Lock className="absolute left-3 top-3 h-4 w-4 text-gray-300" aria-hidden="true" />
          <Input
            id="password"
            name="password"
            type="password"
            placeholder="Create a password"
            value={formData.password}
            onChange={handleInputChange}
            className="pl-10 bg-white/10 border-white/20 text-white placeholder:text-gray-300 focus:border-[#7B4EC3]"
            autoComplete="new-password"
            aria-label="Password"
            required
          />
        </div>
      </div>
    </>
  ), [formData, handleInputChange]);

  const GoogleButton = useMemo(() => (
    <Button
      type="button"
      variant="outline"
      onClick={handleGoogleSignUp}
      disabled={isGoogleLoading}
      className="w-full border-white/20 hover:bg-white/10 text-white focus:ring-2 focus:ring-offset-2 focus:ring-[#7B4EC3]"
      aria-label={isGoogleLoading ? "Signing up with Google..." : "Sign up with Google"}
    >
      {isGoogleLoading ? (
        <Loader2 className="mr-2 h-4 w-4 animate-spin" aria-hidden="true" />
      ) : (
        <img
          src="/images/google.svg"
          alt=""
          className="mr-2 h-5 w-5"
          aria-hidden="true"
        />
      )}
      <span>{isGoogleLoading ? "Signing up..." : "Sign up with Google"}</span>
    </Button>
  ), [handleGoogleSignUp, isGoogleLoading]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-[#1A1A1A]">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-gradient-to-b from-[#5B2E91] to-[#2E7BB7] opacity-5 pointer-events-none" />
      
      {/* Content Container */}
      <div className="w-full max-w-[1200px] min-h-screen flex flex-col lg:flex-row">
        {/* Left Panel - Branding */}
        <div className="relative hidden lg:flex lg:w-1/2 bg-gradient-to-b from-[#5B2E91] to-[#2E7BB7] p-12 flex-col justify-between">
          <div className="relative z-20">
            <img
              src="/images/logo-light.svg"
              alt="Humanii Connect"
              className="h-12"
            />
            <h1 className="mt-12 font-display text-4xl text-white">
              Join Our Healthcare<br />Community
            </h1>
            <p className="mt-4 text-lg text-white/80">
              Connect with like-minded professionals and make a real impact in healthcare.
            </p>
          </div>
          <div className="relative z-20">
            <blockquote className="text-white/90">
              <p className="font-display text-xl">
                "Being part of Humanii Connect has opened up incredible opportunities 
                for collaboration and meaningful change in healthcare."
              </p>
              <footer className="mt-4 text-white/70">
                Dr. Michael Chen<br />
                <span className="text-sm">Medical Research Director</span>
              </footer>
            </blockquote>
          </div>
          {/* Decorative Pattern */}
          <div className="absolute inset-0 opacity-10 bg-[url('/images/pattern.svg')]" />
        </div>

        {/* Right Panel - Form */}
        <div className="w-full lg:w-1/2 p-8 lg:p-12 flex flex-col justify-center">
          <div className="lg:hidden mb-8">
            <img
              src="/images/logo.svg"
              alt="Humanii Connect"
              className="h-10"
            />
          </div>

          <div className="w-full max-w-md mx-auto">
            <div className="mb-8">
              {FormFields}
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="space-y-3">
                <Button
                  type="submit"
                  className="w-full bg-[#7B4EC3] hover:bg-[#6B3EB3] text-white focus:ring-2 focus:ring-offset-2 focus:ring-[#7B4EC3]"
                  disabled={isLoading}
                >
                  {isLoading && (
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" aria-hidden="true" />
                  )}
                  <span>{isLoading ? "Creating account..." : "Create account"}</span>
                </Button>

                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <span className="w-full border-t border-white/20" aria-hidden="true" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="bg-[#1A1A1A] px-2 text-gray-300">or continue with</span>
                  </div>
                </div>

                {GoogleButton}
              </div>
            </form>

            <p className="mt-8 text-center text-sm text-gray-300">
              Already have an account?{" "}
              <a href="/auth/login" className="text-[#7B4EC3] hover:text-[#6B3EB3] font-semibold">
                Sign in
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}
