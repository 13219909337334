import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <div className="flex items-start gap-3 mb-4">
              <img
                src="/icons/icon-192x192.png"
                alt="Humanii Connect"
                className="h-10 w-10 object-contain bg-white rounded-lg p-1"
              />
              <div className="flex flex-col">
                <span className="text-lg font-semibold text-white">
                  Humanii Connect
                </span>
                <span className="text-sm text-gray-400">
                  Connect. Campaign. Create Change.
                </span>
              </div>
            </div>
            <p className="text-gray-400 mt-4">
              Empowering healthcare professionals to connect, collaborate, and
              create meaningful change in healthcare.
            </p>
          </div>

          <div>
            <h4 className="font-semibold mb-4">Quick Links</h4>
            <div className="space-y-2">
              <Link
                to="/"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                Home
              </Link>
              <Link
                to="/how-it-works"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                How It Works
              </Link>
              <Link
                to="/about"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                About
              </Link>
              <Link
                to="/login"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                Login
              </Link>
            </div>
          </div>

          <div>
            <h4 className="font-semibold mb-4">Legal</h4>
            <div className="space-y-2">
              <Link
                to="/privacy"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                Privacy Policy
              </Link>
              <Link
                to="/terms"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                Terms of Service
              </Link>
              <Link
                to="/cookies"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                Cookie Policy
              </Link>
              <Link
                to="/accessibility"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                Accessibility
              </Link>
            </div>
          </div>

          <div>
            <h4 className="font-semibold mb-4">Stay Connected</h4>
            <p className="text-gray-400 mb-4">
              Join our community and stay updated with the latest in healthcare
              networking.
            </p>
            <form onSubmit={(e) => e.preventDefault()} className="space-y-3">
              <div className="flex gap-2">
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="px-3 py-2 rounded bg-gray-800 text-white flex-1 border border-gray-700 focus:outline-none focus:border-[#5B2E91]"
                />
                <Button
                  type="submit"
                  className="bg-[#5B2E91] hover:bg-[#5B2E91]/90 transition-colors"
                >
                  Subscribe
                </Button>
              </div>
              <p className="text-xs text-gray-500">
                By subscribing, you agree to our Privacy Policy and consent to
                receive updates from Humanii Connect.
              </p>
            </form>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-gray-400">
              {new Date().getFullYear()} Humanii Connect. All rights reserved.
            </p>
            <div className="flex gap-6">
              <a
                href="https://twitter.com/humaniiconnect"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                Twitter
              </a>
              <a
                href="https://www.linkedin.com/company/humanii-connect"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                LinkedIn
              </a>
              <a
                href="https://www.instagram.com/humaniiconnect"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
