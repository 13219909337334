import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Plus, BarChart, Users, Mail } from "lucide-react";
import { Progress } from "@/components/ui/progress";

interface Campaign {
  id: string;
  name: string;
  status: "draft" | "active" | "completed" | "scheduled";
  progress: number;
  contacts: number;
  messages: number;
  goal: number;
}

export const CampaignManager = () => {
  const campaigns: Campaign[] = [
    {
      id: "1",
      name: "Summer Fundraiser 2024",
      status: "active",
      progress: 65,
      contacts: 1200,
      messages: 3500,
      goal: 5000,
    },
    {
      id: "2",
      name: "Member Drive Q2",
      status: "draft",
      progress: 0,
      contacts: 0,
      messages: 0,
      goal: 2500,
    },
  ];

  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between">
        <CardTitle>Active Campaigns</CardTitle>
        <Button className="bg-[#4A1B8F] hover:bg-[#4A1B8F]/90">
          <Plus className="h-4 w-4 mr-2" /> New Campaign
        </Button>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {campaigns.map((campaign) => (
            <div key={campaign.id} className="border rounded-lg p-4">
              <div className="flex justify-between items-center mb-2">
                <h3 className="font-semibold">{campaign.name}</h3>
                <span
                  className={`px-2 py-1 rounded-full text-xs ${
                    campaign.status === "active"
                      ? "bg-green-100 text-green-800"
                      : campaign.status === "draft"
                        ? "bg-gray-100 text-gray-800"
                        : "bg-blue-100 text-blue-800"
                  }`}
                >
                  {campaign.status.toUpperCase()}
                </span>
              </div>

              <Progress value={campaign.progress} className="mb-2" />

              <div className="grid grid-cols-3 gap-4 mt-4 text-sm text-gray-600">
                <div className="flex items-center">
                  <Users className="h-4 w-4 mr-2" />
                  {campaign.contacts} contacts
                </div>
                <div className="flex items-center">
                  <Mail className="h-4 w-4 mr-2" />
                  {campaign.messages} messages
                </div>
                <div className="flex items-center">
                  <BarChart className="h-4 w-4 mr-2" />
                  Goal: {campaign.goal}
                </div>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
