import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CampaignManager } from "@/components/dashboard/CampaignManager";

export const CampaignsTab = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <CampaignManager />
      <Card>
        <CardHeader>
          <CardTitle>Campaign Performance</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="h-[300px] flex items-center justify-center text-gray-500">
            Campaign performance visualization coming soon
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
