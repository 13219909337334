import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";

const accountSetupSchema = z.object({
  email: z.string().email("Invalid email address"),
  firstName: z.string().min(2, "First name must be at least 2 characters"),
  lastName: z.string().min(2, "Last name must be at least 2 characters"),
});

type AccountSetupFormData = z.infer<typeof accountSetupSchema>;

export interface AccountSetupProps {
  email: string;
  onComplete: () => void;
}

export function AccountSetup({ email, onComplete }: AccountSetupProps) {
  const form = useForm<AccountSetupFormData>({
    resolver: zodResolver(accountSetupSchema),
    defaultValues: {
      email,
      firstName: "",
      lastName: "",
    },
  });

  const onSubmit = async (data: AccountSetupFormData) => {
    try {
      const { error } = await supabase
        .from("profiles")
        .update({
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName,
          updated_at: new Date().toISOString(),
        })
        .eq("id", 1); // hardcoded user id for demonstration purposes

      if (error) throw error;

      toast.success("Account information saved successfully!");
      onComplete();
    } catch (error) {
      console.error("Error saving account setup:", error);
      toast.error("Failed to save account information");
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-6">Create your account</h2>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="email"
                    placeholder="you@example.com"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="John" />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Doe" />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>

          <div className="flex justify-end">
            <Button type="submit" variant="default">
              Continue
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
};
