import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Mail, Search, Upload, UserPlus, Edit2 } from "lucide-react";
import { useState } from "react";
import { ImportDialog } from "./ImportDialog";
import { AddContactDialog } from "./AddContactDialog";
import { EditContactDialog } from "./EditContactDialog";
import { Contact } from "@/types/contact";
import { useToast } from "../../ui/use-toast";

const mockContacts: Partial<Contact>[] = [
  {
    id: "1",
    firstName: "John",
    lastName: "Doe",
    fullName: "John Doe",
    displayName: "John Doe",
    emails: [
      {
        email: "john@example.com",
        type: "personal",
        isPrimary: true,
        isVerified: false,
        source: "manual",
        lastValidated: new Date(),
      },
    ],
    tags: ["VIP", "Monthly Donor"],
    engagement: {
      score: 85,
      lastContact: new Date("2024-02-15"),
      responseRate: 0.75,
      preferredTimes: ["morning"],
      interests: ["education"],
      lastEngaged: new Date("2024-02-15"),
    },
  },
  {
    id: "2",
    firstName: "Jane",
    lastName: "Smith",
    fullName: "Jane Smith",
    displayName: "Jane Smith",
    emails: [
      {
        email: "jane@example.com",
        type: "personal",
        isPrimary: true,
        isVerified: false,
        source: "manual",
        lastValidated: new Date(),
      },
    ],
    tags: ["Corporate"],
    engagement: {
      score: 70,
      lastContact: new Date("2024-02-14"),
      responseRate: 0.65,
      preferredTimes: ["afternoon"],
      interests: ["healthcare"],
      lastEngaged: new Date("2024-02-14"),
    },
  },
  {
    id: "3",
    firstName: "Mike",
    lastName: "Johnson",
    fullName: "Mike Johnson",
    displayName: "Mike Johnson",
    emails: [
      {
        email: "mike@example.com",
        type: "personal",
        isPrimary: true,
        isVerified: false,
        source: "manual",
        lastValidated: new Date(),
      },
    ],
    tags: ["Event Sponsor"],
    engagement: {
      score: 90,
      lastContact: new Date("2024-02-13"),
      responseRate: 0.9,
      preferredTimes: ["evening"],
      interests: ["non-profit"],
      lastEngaged: new Date("2024-02-13"),
    },
  },
];

export const ContactsList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [contacts, setContacts] = useState<Partial<Contact>[]>(mockContacts);
  const [filteredContacts, setFilteredContacts] =
    useState<Partial<Contact>[]>(mockContacts);
  const [editingContact, setEditingContact] = useState<Partial<Contact> | null>(
    null,
  );
  const { toast } = useToast();

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    const filtered = contacts.filter(
      (contact) =>
        contact.fullName?.toLowerCase().includes(value.toLowerCase()) ||
        contact.emails?.some((email) =>
          email.email.toLowerCase().includes(value.toLowerCase()),
        ) ||
        contact.tags?.some((tag) =>
          tag.toLowerCase().includes(value.toLowerCase()),
        ) ||
        contact.groups?.some((group) =>
          group.name.toLowerCase().includes(value.toLowerCase()),
        ),
    );
    setFilteredContacts(filtered);
  };

  const handleAddContact = (newContact: Partial<Contact>) => {
    const contactWithId: Partial<Contact> = {
      ...newContact,
      id: Date.now().toString(),
    };

    const updatedContacts = [...contacts, contactWithId];
    setContacts(updatedContacts);
    setFilteredContacts(updatedContacts);
  };

  const handleUpdateContact = (updatedContact: Partial<Contact>) => {
    const updatedContacts = contacts.map((contact) =>
      contact.id === updatedContact.id ? updatedContact : contact,
    );
    setContacts(updatedContacts);
    setFilteredContacts(updatedContacts);
    setEditingContact(null);
    toast({
      title: "Contact updated",
      description: "Contact information has been successfully updated.",
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row justify-between gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search contacts..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            className="pl-8"
          />
        </div>
        <div className="flex gap-2 flex-wrap">
          <ImportDialog
            type="csv"
            trigger={
              <Button variant="outline">
                <Upload className="h-4 w-4 mr-2" />
                Import CSV
              </Button>
            }
            onImportComplete={(contacts) => {
              const updatedContacts = [...filteredContacts, ...contacts];
              setContacts(updatedContacts);
              setFilteredContacts(updatedContacts);
            }}
          />
          <ImportDialog
            type="phone"
            trigger={
              <Button variant="outline">
                <Upload className="h-4 w-4 mr-2" />
                Phone Contacts
              </Button>
            }
          />
          <AddContactDialog
            trigger={
              <Button variant="default">
                <UserPlus className="h-4 w-4 mr-2" />
                Add Contact
              </Button>
            }
            onAddContact={handleAddContact}
          />
        </div>
      </div>

      <ScrollArea className="h-[500px] rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Tags</TableHead>
              <TableHead>Group</TableHead>
              <TableHead>Last Contact</TableHead>
              <TableHead>Engagement Score</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredContacts.map((contact) => (
              <TableRow key={contact.id}>
                <TableCell className="font-medium">
                  {contact.displayName}
                </TableCell>
                <TableCell>{contact.emails?.[0]?.email}</TableCell>
                <TableCell>
                  <div className="flex flex-wrap gap-1">
                    {contact.tags?.map((tag, index) => (
                      <span
                        key={index}
                        className="px-2 py-1 bg-accent text-accent-foreground rounded-full text-xs"
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </TableCell>
                <TableCell>{contact.groups?.[0]?.name}</TableCell>
                <TableCell>
                  {contact.engagement?.lastContact.toLocaleDateString()}
                </TableCell>
                <TableCell>
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${
                      (contact.engagement?.score || 0) > 70
                        ? "bg-green-100 text-green-800"
                        : "bg-yellow-100 text-yellow-800"
                    }`}
                  >
                    {contact.engagement?.score || 0}
                  </span>
                </TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => setEditingContact(contact)}
                    >
                      <Edit2 className="h-4 w-4 mr-2" />
                      Edit
                    </Button>
                    <Button variant="ghost" size="sm">
                      <Mail className="h-4 w-4 mr-2" />
                      Message
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollArea>

      {editingContact && (
        <EditContactDialog
          contact={editingContact}
          isOpen={!!editingContact}
          onClose={() => setEditingContact(null)}
          onSave={handleUpdateContact}
        />
      )}
    </div>
  );
};
