import { NonIndexRouteObject, Outlet, Navigate } from 'react-router-dom';
import MainLayout from '@/components/layouts/MainLayout';
import { DashboardLayout } from '@/components/layouts/DashboardLayout';
import Register from '@/pages/auth/register';
import LoginPage from '@/pages/auth/login';
import AuthCallback from '@/pages/auth/callback';
import VerifyEmail from '@/pages/auth/verify-email';
import Dashboard from '@/pages/Dashboard';
import Profile from '@/pages/Profile';
import Settings from '@/pages/settings';
import CompleteProfile from '@/pages/auth/complete-profile';
import TermsOfUse from '@/components/TermsOfUse';
import PrivacyPolicy from '@/components/PrivacyPolicy';

export interface ExtendedRoute extends NonIndexRouteObject {
  auth?: boolean;
  i18nNamespace?: string;
  children?: ExtendedRoute[];
}

const routes: ExtendedRoute[] = [
  {
    path: '/dashboard',
    element: <DashboardLayout><Outlet /></DashboardLayout>,
    auth: true,
    children: [
      {
        path: '',
        element: <Dashboard />,
        i18nNamespace: 'dashboard',
      },
      {
        path: 'profile',
        element: <Profile />,
        i18nNamespace: 'profile',
      },
      {
        path: 'settings',
        element: <Settings />,
        i18nNamespace: 'settings',
      },
    ],
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="/login" replace />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'auth/callback',
        element: <AuthCallback />,
      },
      {
        path: 'auth/verify-email',
        element: <VerifyEmail />,
      },
      {
        path: 'auth/complete-profile',
        element: <CompleteProfile />,
        auth: true,
      },
      {
        path: 'terms',
        element: <TermsOfUse />,
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />,
      },
      {
        path: '*',
        element: <Navigate to="/login" replace />,
      }
    ],
  },
];

export default routes;
