import { createContext, useContext, useEffect, useState } from 'react';
import { User, Session } from '@supabase/supabase-js';
import { supabase } from '@/lib/supabase';
import type { UserProfile } from '@/types/profile';

export interface AuthResult {
  data: {
    user: User | null;
    session: Session | null;
  } | null;
  error: {
    message: string;
  } | null;
}

export interface AuthContextType {
  user: User | null;
  session: Session | null;
  isLoading: boolean;
  profile: UserProfile | null;
  signInWithGoogle: () => Promise<AuthResult>;
  signInWithEmail: (email: string, password: string) => Promise<AuthResult>;
  signUp: (credentials: {
    email: string;
    password: string;
    fullName: string;
  }) => Promise<AuthResult>;
  signOut: () => Promise<void>;
  updateUser: (data: {
    email?: string;
    settings?: {
      notifications: {
        email: boolean;
        push: boolean;
      };
      theme: string;
      language: string;
    };
  }) => Promise<AuthResult>;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [profile, setProfile] = useState<UserProfile | null>(null);

  const signInWithGoogle = async (): Promise<AuthResult> => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
          scopes: 'email profile',
        }
      });

      if (error) {
        return {
          data: null,
          error: { message: error.message }
        };
      }

      return {
        data: null,
        error: null
      };
    } catch (error) {
      return {
        data: null,
        error: {
          message: error instanceof Error ? error.message : 'Failed to sign in with Google'
        }
      };
    }
  };

  const signInWithEmail = async (email: string, password: string): Promise<AuthResult> => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      if (error) {
        return {
          data: null,
          error: { message: error.message }
        };
      }

      return {
        data: {
          user: data.user,
          session: data.session
        },
        error: null
      };
    } catch (error) {
      return {
        data: null,
        error: {
          message: error instanceof Error ? error.message : 'Failed to sign in with email'
        }
      };
    }
  };

  const signUp = async (credentials: {
    email: string;
    password: string;
    fullName: string;
  }): Promise<AuthResult> => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email: credentials.email,
        password: credentials.password,
        options: {
          data: {
            full_name: credentials.fullName,
          }
        }
      });

      if (error) {
        return {
          data: null,
          error: { message: error.message }
        };
      }

      return {
        data: {
          user: data.user,
          session: data.session
        },
        error: null
      };
    } catch (error) {
      return {
        data: null,
        error: {
          message: error instanceof Error ? error.message : 'An error occurred during sign up'
        }
      };
    }
  };

  const signOut = async () => {
    await supabase.auth.signOut();
  };

  const updateUser = async (data: {
    email?: string;
    settings?: {
      notifications: {
        email: boolean;
        push: boolean;
      };
      theme: string;
      language: string;
    };
  }): Promise<AuthResult> => {
    try {
      const { data: updateData, error } = await supabase.auth.updateUser({
        email: data.email,
        data: {
          settings: data.settings
        }
      });

      if (error) {
        return {
          data: null,
          error: { message: error.message }
        };
      }

      return {
        data: {
          user: updateData.user,
          session: null
        },
        error: null
      };
    } catch (error) {
      return {
        data: null,
        error: {
          message: error instanceof Error ? error.message : 'An error occurred while updating user'
        }
      };
    }
  };

  useEffect(() => {
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setUser(session?.user ?? null);
        setSession(session ?? null);
        setIsLoading(false);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user?.id) {
      const loadProfile = async () => {
        try {
          let profileData = null;
          const { data, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', user.id)
            .single();

          if (error) {
            console.error('Error loading profile:', error);
            setProfile(null);
            return;
          }

          if (!data) {
            // Create profile if it doesn't exist
            const { data: newProfile, error: createError } = await supabase
              .from('profiles')
              .insert([
                {
                  id: user.id,
                  email: user.email,
                  full_name: user.user_metadata?.full_name || '',
                  is_onboarded: false,
                }
              ])
              .select()
              .single();

            if (createError) {
              console.error('Error creating profile:', createError);
              setProfile(null);
              return;
            }

            profileData = newProfile;
          } else {
            profileData = data;
          }

          setProfile({
            id: profileData.id,
            email: user.email || null,
            fullName: profileData.full_name,
            firstName: profileData.first_name,
            lastName: profileData.last_name,
            organizationName: profileData.organization_name,
            role: profileData.role,
            socialMediaProfiles: profileData.social_media_profiles || [],
            avatarUrl: profileData.avatar_url,
            isOnboarded: profileData.is_onboarded,
            createdAt: profileData.created_at,
            updatedAt: profileData.updated_at
          });
        } catch (error) {
          console.error('Error in profile loading:', error);
          setProfile(null);
        }
      };

      loadProfile();
    } else {
      setProfile(null);
    }
  }, [user]);

  const value = {
    user,
    session,
    isLoading,
    profile,
    signInWithGoogle,
    signInWithEmail,
    signUp,
    signOut,
    updateUser
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
